// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    font-family: $font-family-3;
    font-weight: normal;
    font-size: $font-size-20;
    color: #5b4a4c;
    line-height: 1.7;
    margin: 0;
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
// @import 'modules/menu';
@import 'modules/menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-btn {
    .left-side {
        background: #faf4f5;
        width: 40%;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5% 10px 5%;
    } 
    .center-side {
        width: 25%;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2%;
        h4 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-28;
            color: #515152;
        }
    }
    .right-side {
        background: url(../images/k_section02.png) no-repeat, #dfb7bb;
        width: 40%;
        min-height: 550px;
        background-size: contain;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: end;
        padding: 130px 25px;
        align-items: center;
        h3 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-36;
            color: #a43f4a;
            padding-bottom: 10px;
        }
        h4 {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-white;
            padding-bottom: 5px;
        }
        a {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-white;
            transition: 0.3s;
            &:hover {
                color: #a43f4a;
                transition: 0.3s;
            }
        }
    }
    h3 {
        font-family: $font-family-1;
        font-weight: normal;
        font-size: $font-size-50;
        color: #a43f4a;
        padding-bottom: 50px;
    }
    p {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-20;
        color: #515152;
        line-height: 1.3;
        margin: 0;
    }
    @media screen and (max-width: 1550px) {
        .center-side {
            width: 34%;
        }
        @media screen and (max-width: 1455px) {
            h3 {
                font-size: 2.25rem;
            }
            @media screen and (max-width: 1330px) {
                h3 {
                    font-size: 2.15rem;
                }
                .right-side {
                    h3 {
                        font-size: 1.65rem;
                    }
                }
                @media screen and (max-width: 1165px) {
                    flex-direction: column;
                    .left-side, .center-side {
                        width: 100%;
                        min-height: initial;
                        padding: 65px 5%;
                    }
                    .right-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .right-side {
                            min-height: 445px;
                            padding: 50px 25px;
                        }
                    }
                }
            }
        }
    }
}

#section-parallax {
    background: url(../images/accueil_paralax_section.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 807px;
    @media screen and (max-width: $size-xs-max) {
        min-height: 600px;
        background-attachment: initial;
    }
}

#section-services {
    background: #e7c9cc;
    padding: 35px 5%;
    h3 {
        font-family: $font-family-1;
        font-weight: normal;
        font-size: $font-size-50;
        text-align: center;
        color: #a43f4a;
        padding-bottom: 50px;
    }
    .burst {
        width: 30%;
        padding-right: 25px;
        .title {
            text-align: center;
            padding-bottom: 15px;
            h4 {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-30;
                color: #a43f4a;
            }
        }
    }
    a.burst {
        .image {
            position: relative;
            .overlay {
                background-color: rgba(255, 255, 255, 0.4);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        }
        &:hover {
            .image {
                .overlay {
                     opacity: 1;
                }   
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        padding: 50px 5%;
        .burst {
            width: 40%;
        }
        @media screen and (max-width: $size-xs-max) {
            .services.flex.justify-center {
                flex-direction: column;
                .burst {
                    width: 100%;
                    padding: 0 0 35px 0;
                }
            }
        }
    }
}

#section-formulaire {
    padding: 75px 2%;
    background: #b13648;
    text-align: center;
    h3 {
        font-weight: 300;
        font-size: $font-size-50;
        color: $color-white;
        padding-bottom: 50px;
    }
    p {
        font-family: $font-family-2;
        font-size: $font-size-24;
        color: #e0a5ad;
        line-height: 1.3;
        span {
            font-weight: bold;
            color: $color-white;
        }
    }
    .border {
        background: #fff;
        height: 1px;
        width: 200px;
        margin: 50px auto 0 auto;
    }
    .section-info-form {
        padding-top: 100px;
        .section-info {
            margin-right: 8%;
            a.bloc {
                .icon {
                    padding-right: 15px;
                    position: relative;
                    img {
                        &:nth-child(1) {
                            opacity: 1;
                            transition: 0.3s;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                            top: 0;
                            transition: 0.3s;
                        }
                    }
                }
                .text {
                    width: 80%;
                    p {
                        font-family: $font-family-3;
                        font-size: $font-size-20;
                        color: #fff;
                    }
                }
                &:hover {
                    img {
                        &:nth-child(1) {
                            opacity: 0;
                            transition: 0.3s;
                        }
                        &:nth-child(2) {
                            opacity: 1;
                            transition: 0.3s;
                        }
                    }
                }
            }
        }
        .section-form {
            width: 55%;
            #right {
                display: flex;
                justify-content: right;
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .section-info-form {
            .section-form {
                width: 65%;
            }
        }
        @media screen and (max-width: 1400px) {
            .section-info-form {
                .section-form {
                    width: 75%;
                }
            }
            @media screen and (max-width: 1210px) {
                p br {
                    display: none;
                }
                @media screen and (max-width: $size-sm-max) {
                    .section-info-form {
                        flex-direction: column;
                        padding-top: 65px;
                        .section-info {
                            margin: 0 0 35px 0;
                            a.bloc {
                                justify-content: center;
                                .icon {
                                    padding-right: 0;
                                    width: 10%;
                                    .img-responsive {
                                        margin: auto;
                                    }
                                }
                                .text {
                                    width: initial;
                                }
                            }
                        }
                        .section-form {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#section-clients {
    padding: 75px 2% 0 2%;
    h4, h3 {
        text-align: center;
        color: #a43f4a;
    }
    h3 {
        font-weight: normal;
        font-size: $font-size-50;
    }
    h4 {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-38;
    }

    .main-content {
        position: relative;
        padding: 50px 5% 25px 5%;
        .owl-item {
            height: 585px;
            display: flex;
            flex-direction: column;
            padding: 50px 35px;
            align-items: center;
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
                .image img {
                    width: initial;
                    margin: auto;
                }
                .text {
                    padding-top: 35px;
                    h4 {
                        font-size: $font-size-28;
                        padding-bottom: 25px;
                    }
                    p {
                        font-family: $font-family-1;
                        font-weight: 300;
                        font-size: $font-size-18;
                        color: #7d6669;
                        line-height: 1.3;
                    }
                }
            }
            &:nth-child(even) {
                background: #f8f8f8;
            }
            @media screen and (max-width: 1765px) {
                height: 635px;
                @media screen and (max-width: 1550px) {
                    height: 665px;
                    @media screen and (max-width: 1450px) {
                        height: 585px;
                        @media screen and (max-width: $size-sm-max) {
                            height: 500px;
                            @media screen and (max-width: $size-xs-max) {
                                height: 550px;
                            }
                        }
                    }
                }
            }
        }
        .owl-theme {
            .custom-nav {
                position: absolute;
                top: 20%;
                left: 0;
                right: 0;

                .owl-prev, .owl-next {
                    position: absolute;
                    height: 100px;
                    color: inherit;
                    background: none;
                    border: none;
                    z-index: 100;
                }
            }
        }
        .arrow.prev.next-vedette {
            position: absolute;
            left: 20px;
            top: 50%;
            z-index: 1;
            @media screen and (max-width: $size-md-max) {
                left: 5px;
                @media screen and (max-width: $size-sm-max) {
                    left: -5px;
                    @media screen and (max-width: $size-xs-max) {
                        left: 0;
                        width: 4%;
                    }
                }
            }
        }
        .arrow.next.prev-vedette {
            position: absolute;
            right: 20px;
            top: 50%;
            z-index: 1;
            @media screen and (max-width: $size-md-max) {
                right: 5px;
                @media screen and (max-width: $size-sm-max) {
                    right: -5px;
                    @media screen and (max-width: $size-xs-max) {
                        right: 0;
                        width: 4%;
                    }
                }
            }
        }
        .arrow {
            .img-responsive.hover {
                display: none;
            }
            &:hover {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 50px 2% 0 2%;
    }
}

#section-partenariat {
    padding: 100px 2%;
    h3 {
        font-weight: normal;
        font-size: $font-size-50;
        text-align: center;
        color: #a43f4a;
    }
    .section-bloc {
        padding-top: 100px;
        .bloc {
            width: 27%;
            height: 245px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h3 {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-34;
                color: #a43f4a;
                text-transform: initial;
                span {
                    font-weight: 600;
                }
            }
            h5 {
                font-family: $font-family-1;
                font-size: $font-size-26;
                font-weight: normal;
                color: #010101;
                padding-top: 35px;
                span {
                    font-weight: bold;
                }
            }
            p {
                font-family: $font-family-2;
                font-size: $font-size-22;
                font-weight: normal;
                text-align: center;
                color: #010101;
                line-height: 1.3;
                padding-top: 25px;
            }
        }
        .bloc.left {
            background: url(../images/accueil_section6_gold.jpg) no-repeat;
            margin-right: 292px;
        }
        .bloc.right {
            background: url(../images/accueil_section6_platine.jpg) no-repeat;
        }
    } 
    @media screen and (max-width: 1795px) {
        .section-bloc {
            .bloc {
                h3 {
                    font-size: 1.45rem;
                }
            }
        }
        @media screen and (max-width: 1550px) {
            .section-bloc {
                .bloc {
                    width: 35%;
                }
                .bloc.left {
                    margin-right: 175px;
                }
            }
            @media screen and (max-width: 1150px) {
                .section-bloc {
                    .bloc {
                        width: 45%;
                    }
                    .bloc.left {
                        margin-right: 100px;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .section-bloc {
                        .bloc {
                            h3 {
                                font-size: 1.35rem;
                            }
                            h5 {
                                font-size: 1.1rem;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 75px 3%;
                        .section-bloc {
                            flex-direction: column;
                            padding-top: 65px;
                            .bloc {
                                width: 100%;
                                h3 {
                                    font-size: 1.7rem;
                                }
                                h5 {
                                    font-size: 1.3rem;
                                }
                            }
                            .bloc.left {
                                margin: 0 0 25px 0;
                            }
                        }
                    }  
                }
            }
        }   
    }
}

#section-footer-menu {
    .section-footer {
        background: url(../images/bg_footer.jpg) no-repeat;
        background-size: cover;
        margin-left: -1px;
        min-height: 399px;
        padding: 50px 15% 45px 15%;
        .logo {
            padding-bottom: 35px;
            width: 25%;
        }
        .section-menu {
            h5 {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-22;
                color: #d8a7ad;
                padding-bottom: 5px;
            }
            a {
                h5 {
                    transition: 0.3s;
                    &:hover {
                        color: $color-white;
                        transition: 0.3s;
                    }
                }
            }
        }
    }

    .section-reseau {
        background: #d6a6ac;
        background-position: center;
        background-size: cover;
        height: 79px;
        width: 100%;
        padding: 0 13%;
        .left-side {
            width: 33.3333%;
        }
        .center-side {
            h4 {
                font-family: $font-family-2;
                font-weight: bold;
                font-size: $font-size-30;
                color: #a43f4a;
                transition: 0.3s;
                &:hover {
                    color: $color-white;
                    transition: 0.3s;
                }
            }
        }
        .right-side {
            h5 {
                font-family: $font-family-2;
                font-weight: bold;
                font-size: $font-size-22;
                color: $color-white;
                padding-right: 35px;
            }
            a {
                width: 10%;
                &:hover {
                    .icon {
                        transform: scale(1.1);   
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .section-reseau {
            padding: 0;
            justify-content: space-evenly;
           .left-side {
                display: none;
            }
        }
        @media screen and (max-width: 1024px) {
            .section-footer {
                padding: 50px 5% 45px 5%;
            }
            @media screen and (max-width: $size-sm-max) {
                .section-reseau {
                    justify-content: flex-end;
                    .center-side {
                        margin-right: 15%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-footer {
                        padding: 50px 5%;
                        .logo {
                            width: 60%;
                        }
                        .section-menu.flex.justify-around {
                            flex-direction: column;
                            .right {
                                padding-top: 35px;
                            }
                        }
                    }
                    .section-reseau {
                        flex-direction: column;
                        height: initial;
                        padding: 35px 0;
                        .center-side {
                            margin-right: 0;
                            margin-bottom: 25px;
                        }
                        .right-side.flex.items-center {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* PRISE DE SANG + SOIN DES PIEDS
*******************************************************************************/

#section-intro.services {
    background: #fff;
    padding: 100px 5% 0 5%;
    text-align: center;
    h3 {
        font-family: $font-family-1;
        font-weight: normal;
        font-size: $font-size-46;
        color: #a43f4a;
        padding-bottom: 35px;
    }
    p {
        font-family: $font-family-2;
        line-height: 1.5;
        a {
            font-family: $font-family-2;
            color: #a43f4a;
            &:hover {
                border-bottom: 1px solid #a43f4a;
            }
        }
    }
    p.red {
        font-weight: bold;
        font-size: $font-size-24;
        color: #a43f4a;
    }
}

#section-forfait {
    padding: 75px 5%;
    .forfait {
        width: 45%;
        padding: 100px 70px;
        h4 {
            font-family: $font-family-1;
            font-size: $font-size-34;
            font-weight: normal;
            text-align: center;
            color: #010101;
            padding-bottom: 35px;
            span {
                font-weight: bold;
            }
        }
        ul {
            li {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-20;
                color: #a43f4a;
                text-transform: initial;
                line-height: 1.5;
                padding-bottom: 5px;
            } 
        }
    }
    .left-side {
        background: url(../images/accueil_section6_gold.jpg) no-repeat;
        background-size: cover;
    }
    .right-side {
        background: url(../images/accueil_section6_platine.jpg) no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1550px) {
        .forfait {
            width: 48%;
        }
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            .forfait {
                width: 75%;
                margin: auto;
            }
            .forfait.first {
                margin-bottom: 35px !important;
            }
            @media screen and (max-width: 1024px) {
                .forfait {
                    width: 80%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .forfait {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 40px 5%;
                        .forfait {
                            padding: 75px 20px;
                        }
                    }
                }
            }
        }
    }
}

#section-rdv-text {
    .left-side {
        background: url(../images/k_section02.png) no-repeat, #dfb7bb;
        min-height: 500px;
        background-size: contain;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: end;
        padding-bottom: 75px;
        align-items: center;
        width: 35%;
        h3 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-36;
            color: #a43f4a;
            padding-bottom: 5px;
        }
        h4 {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-white;
            padding-bottom: 5px;
        }
        a {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-32;
            color: $color-white;
            transition: 0.3s;
            &:hover {
                color: #a43f4a;
                transition: 0.3s;
            }
        }
    }
    .right-side {
        width: 65%;
        background: #faf4f5;
        padding: 0 5%;
        h3 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-46;
            color: #a43f4a;
            padding-bottom: 35px;
        }
        p {
            font-family: $font-family-2;
            line-height: 1.5;
        }
    }
    @media screen and (max-width: 1450px) {
        .left-side {
            min-height: 435px;
            width: 45%;
        }
        .right-side h3 {
            font-size: 2.15rem;
        }
        @media screen and (max-width: 1100px) {
            flex-direction: column-reverse;
            .right-side {
                width: 100%;
                padding: 75px 5%;
            }
            .left-side {
                width: 100%;
                padding-bottom: 50px;
            }
            @media screen and (max-width: $size-sm-max) {
                .left-side {
                    min-height: 380px;
                }
                @media screen and (max-width: 360px) {
                    .left-side {
                        min-height: 285px;
                        padding-bottom: 35px;
                    }
                }
            }
        }
    }
}

#section-intro {
    background: #fff;
    padding: 75px 0 0 0;
    .title-text {
        padding: 0px 5% 75px 5%;
    }
    h3 {
        font-family: $font-family-1;
        font-weight: normal;
        font-size: $font-size-46;
        color: #a43f4a;
        padding-bottom: 25px;
    }
    p {
        font-family: $font-family-2;
        line-height: 1.5;
    }
    .title.pt8 {
        background: #faf4f5;
        padding: 35px 5% 0 5%;
    }
    @media screen and (max-width: 360px) {
        h3 {
            font-size: 2.1rem;
        }
    }
}

#section-intro.soin {
    padding: 50px 5%;
    h3 {
        padding-bottom: 0;
    }
}

.section-faq {
    padding: 50px 5%;
    background: #faf4f5;
    &:nth-child(odd) {
      background: $color-white;
    }
    p {
        font-family: $font-family-2;
        line-height: 1.5;
    }
    .section-question {
        padding-bottom: 25px;
        .left-side {
            width: 6%;
        }
        .right-side {
            width: 100%;
        }
    }
    .section-answer {
        .left-side {
            width: 6%;
        }
        .right-side {
            width: 100%;
        }
    }
    @media screen and (max-width: 1550px) {
        .section-question .left-side, .section-answer .left-side {
            width: 8%;
        }
        @media screen and (max-width: 1024px) {
            .section-question .left-side, .section-answer .left-side {
                width: 12%;
            }
            @media screen and (max-width: $size-sm-max) {
                .section-question .left-side, .section-answer .left-side {
                    width: 14%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-question, .section-answer {
                        flex-direction: column;
                        .left-side {
                            width: 100%;
                            .img-responsive {
                                margin: auto;
                                padding-bottom: 5px;
                            }
                        }
                        .right-side {
                            text-align: center;
                            p {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    .left-side {
        background: #faf4f5;
        width: 60%;
        height: 600px;
        padding: 100px 5%;
        p {
            font-family: $font-family-2;
        }
        .info {
            padding-top: 10px;
            h4 {
                font-size: $font-size-20;
                color: #a43f4a;
            }
        }
    }
    .right-side {
        background: #f2e2e4;
        width: 40%;
        padding: 100px 5%;
        h3 {
            font-family: $font-family-4;
            font-weight: normal;
            text-transform: initial;
            font-style: italic;
            font-size: $font-size-46;
            color: #a43f4a;
            padding-bottom: 25px;
            text-align: center;
        }
        h5 {
            font-weight: normal;
            font-size: $font-size-20;
            color: #515152;
        }
    }
    @media screen and (max-width: $size-md-max) {
        .left-side, .right-side {
            height: 475px;
            padding: 65px 5%;
        }
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            .left-side, .right-side {
                height: initial;
                padding: 75px 5%;
                width: 100%;
            }
            .left-side {
                .info {
                    padding-top: 75px;
                }
            }
        }
    }
}

#section-formulaire.coordonnees {
    border-bottom: 1px solid #fff;
}

div#content {
    h3#firstHeading {
        color: #a43f4a;
    }
    p a {
        color: #515152;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   
    nav.module-menu #menu-mobile ul li .submenu li {
        padding-bottom: 5px;
    }
}
